<template>
  <div class="Details">
    <div class="Details-top">
      <div class="Details-top-left">
        <span>{{ formatDate(details.createDate).day }}/ </span>
        <span>
          {{ formatDate(details.createDate).month }}月<br />
          {{ formatDate(details.createDate).year }}
        </span>
      </div>
      <div class="Details-top-right">
        <div class="title">{{ details.title }}</div>
        <div class="desc">
          <span class="source">来源：{{ details.source }}</span>
          <span class="date">发布时间：{{ details.createDate }}</span>
          <span class="author">作者：{{ details.author }}</span>
        </div>
      </div>
    </div>
    <div class="Details-content" v-html="details.content"></div>
    <div class="Details-footer">
      <div class="pre">
        <span class="btn" @click="handlePrevNext(prev)">上一条：</span>
        <span>{{ prev ? prev.title : '无' }}</span>
      </div>
      <div class="next">
        <span class="btn" @click="handlePrevNext(next)">下一条：</span>
        <span>{{ next ? next.title : '无' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Details',
    data() {
      return {
        details: {
          createDate: '2023-08-28 15:32:58',
          title: '跨境电商企业必看！2023年EPR政策汇总（超详细）',
          source: '网易新闻',
          author: '胶州湾中保区',
          content: ''
        },
        prev: '',
        next: ''
      }
    },
    watch: {
      '$route.params.id': {
        immediate: true,
        handler(id) {
          this.getDetail(id);
        }
      }
    },
    mounted () {
    },
    methods: {
      async getDetail(id) {
        const res = await this.$request.get(`/article/detail/${id}`);
        const { article, next, prev } = res.data;
        this.details = article;
        this.prev = prev;
        this.next = next;
      },
      handlePrevNext(record) {
        if(record) {
          const { id } = record;
          this.getDetail(id);
        }
      },
      formatDate(date) {
        const newDate = new Date(date);
        let year = newDate.getFullYear();
        let month = `${newDate.getMonth() + 1}`.padStart(2, '0');
        let day = `${newDate.getDay()}`.padStart(2, '0');
        return {
          year,
          month,
          day
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.Details{
  &-top{
    display: flex;
    padding-bottom: 50px;
    border-bottom: 1px solid rgba(193, 193, 201, 0.3);
    &-left{
      width: 180px;
      height: 140px;
      background: #3484FB;
      display: flex;
      justify-content: center;
      align-items: center;
      span:first-child{
        font-size: 48px;
        font-family: DINAlternate-Bold, DINAlternate;
        // font-weight: bold;
        color: #FFFFFF;
      }
      span:last-child{
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #FFFFFF;
      }
    }
    &-right{
      flex: 1;
      margin-left: 45px;
      .title{
        padding-top: 60px;
        padding-left: 60px;
        margin-bottom: 20px;
        font-size: 24px;
        color: #171717;
      }
      .desc{
        display: flex;
        span{
          margin-right: 100px;
          color: #5F5F6A;
        }
      }
    }
  }
  &-content{
    // line-height: 30px;
    padding: 50px 0;
    border-bottom: 1px solid rgba(193, 193, 201, 0.3);
    // color: #5F5F6A;
    ::v-deep(img){
      width: 100%;
      object-fit: fill;
    }
  }
  &-footer{
    margin-top: 20px;
    .pre,
    .next{
      line-height: 30px;
      span{
        &.btn{
          color: #3484FB;
          cursor: pointer;
        }
      }
    }
  }
}
</style>